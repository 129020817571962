import React, { useState, useEffect } from "react"
import { bannerImage } from "../../../data/global"
import Image from "atomic-core/components/patterns/01-atoms/images/Image"
import Text from "atomic-core/components/patterns/01-atoms/texts/text/Text"
import Media from "atomic-core/components/patterns/02-molecules/media/Media"
import Description from "atomic-core/components/patterns/02-molecules/description/Description"
import * as moment from "moment"
import { getNestedObjectValue } from "../../../utils/global"

const Banner = () => {
  const [eventDetails, setEventDetails] = useState({
    eventDate: "",
  })

  const fetchEventDetails = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/getEventDetails`
    )
    let data = await response.json()
    let eventDate = ""
    let event = getNestedObjectValue(data, "result.event")
    if (event && event.startDate && event.endDate) {
      let startDate = moment.utc(event.startDate).format("DD MMM YYYY")
      let endDate = moment.utc(event.endDate).format("DD MMM YYYY")
      if (startDate == endDate) {
        eventDate = startDate
      } else {
        eventDate = `${startDate} - ${endDate}`
      }
    }

    setEventDetails({
      eventDate: eventDate,
    })
  }

  useEffect(() => {
    fetchEventDetails()
  }, [])

  return (
    <div className="width-full mb-0 hero">
      <Description globalModifiers={"pl-20"}>
        {/* <Text globalModifiers={"text-size-h5"}>{eventDetails.eventDate}</Text> */}
      </Description>
      
        <Image src={bannerImage} stretching={"image--background"} />
      
    </div>
  )
}

export default React.memo(Banner)
